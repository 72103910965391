













import { Prop, Vue, Component } from "vue-property-decorator"
import { state } from "@/store"

@Component({})
export default class Bottombar extends Vue {
  @Prop({ default: false }) toolbox!: boolean
  shadowed = false

  get state() {
    return state
  }

  onWheel() {
    const html = document.documentElement
    this.shadowed = html.scrollTop < html.scrollHeight - html.offsetHeight
  }

  mounted() {
    addEventListener("wheel", this.onWheel, { passive: true })
    this.onWheel()
  }

  destroyed() {
    removeEventListener("wheel", this.onWheel)
  }
}
